export const initialListQuery = {
  page: 1,
  limit: 50,
  // status: { value: "", label: "Select status" },
  search: "",
  fromdate: "",
  todate: "",
  sort: "",
  sortvalue: "",
  sortorder:"",
  sortbycoinearned:""
};

const initialState = {
  statdata: [],
  listdata: [],
  data: [],
  userListLoader: true,
  total_documents: null,
  usersListQuery: { ...initialListQuery },
  downloadData:[],
};

const coinEarningHistory = (state = initialState, action) => {
  switch (action.type) {
    case "GET_COIN_EARNING_HISTORY_STAT_DATA":
      return {
        ...state,
        statdata: action.data,
      };
    case "GET_COIN_EARNING_HISTORY_LIST_DATA":
      // console.log(action.data)
      return {
        ...state,
        listdata: action.data,
        total_documents: action.total_documents,
      };
      case "GET_COIN_EARNING_HISTORY_DOWNLOAD_DATA":
      // console.log(action.data)
      return {
        ...state,
        downloadData: action.data,
      };
      case "COIN_EARNING_HISTORY_LIST_LOADER": {
        return {
          ...state,
          userListLoader: action.loading,
        };
      }
    case "SET_COIN_EARNING_HISTORY_LIST_QUERY": {
      return {
        ...state,
        usersListQuery: {
          ...state.usersListQuery,
          ...action.query,
        },
      };
    }
    default:
      return { ...state };
  }
};
export default coinEarningHistory;
